main {
  min-height: 80vh;
}

.campaign-name,
.subject-name {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}

table td,
table th {
  text-align: center;
}

/* Subheader */
.active-link {
  font-weight: bold;
  /* color: #10633b; Change to your preferred color */
  background-color: #e3fff4; /* Change to your preferred background color */
}

.custom-link {
  text-decoration: none;
  color: inherit;
  display: block;
  margin-bottom: 4px;
}

.custom-link:hover {
  color: orange;
}

.custom-accordion .accordion-item {
  border: none;
  background-color: transparent;
  padding: 0;
}

.custom-accordion .accordion-header {
  background-color: transparent;
  border: none;
  padding: 0;
}

.custom-accordion .accordion-header:hover {
  color: orange;
}

.accordion-button {
  padding: 0;
}

.accordion-button:hover {
  color: orange;
}

.dotted-divider {
  border-top: 1px dotted #ccc;
  margin: 8px 0;
}

/* Custom styles for Tab.Pane */
.custom-tab-pane {
  padding: 1rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
